<template>
    <about-subpage :charName="'huis'">
        <template v-slot:content>
            <p class="mb-2">Character designs for</p>
            <h1 class="title">Huis</h1>
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/Huis.jpg" />
            <h2 class="subtitle mt-5">3D Reference Model</h2>
            <p>
                Built in Nomad Scuplt on iPad. Human models found online, not
                mine.
            </p>
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/huis-model-wide.jpg" />
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/huis-model-closeup.jpg" />
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/huis-model-back.jpg" />
            <img @click="open"
                 class="open"
                 src="@/assets/img/character-sheets/huis-model-right-side.jpg" />
            <!-- <img @click="open" class="open" src="@/assets/img/character-sheets/Huis2.jpg" /> -->
            <h2 class="subtitle mt-5">NSFW</h2>
            <p>Huis naked and free!!!</p>
            <div @click="hideImage1 = !hideImage1"
                 :class="{ spoiler: hideImage1 }">
                <img src="@/assets/img/character-sheets/huis-naked.jpg" />
            </div>
        </template>
    </about-subpage>
</template>

<script>
import AboutSubpage from "@/components/AboutSubpage.vue";

export default {
    name: "Huis",
    components: { AboutSubpage },
    data() {
        return {
            hideImage1: true,
        };
    },
    methods: {
        open(event) {
            window.open(event.target.src, '_blank')
        },
    }
};
</script>
